import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import { TagEventTracker } from 'components/TagEventTracker';

import { nl2br } from '../../utils/functions';
import { RecipeProps } from './models';

import 'styles/main.scss';
import './Recipe.scss';

const Recipe: FC<{ data: RecipeProps }> = ({
  data: {
    recipe: {
      urls,
      interlink,
      seo,
      title,
      imageDesktop,
      imageMobile,
      ingredients,
      difficulty,
      time,
      portions,
      nutritional,
      steps,
      headband,
    },
    global,
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <TagEventTracker eventType="page_view" data={{ value: seoTitle }}>
        <div className="recipe">
          <div className="recipe__banner">
            <Image
              imageData={imageDesktop}
              alt={imageDesktop.altText}
              className="d-none d-md-block"
            />
            <Image
              imageData={imageMobile}
              alt={imageMobile.altText}
              className="d-block d-md-none"
            />
            <div className="container-fluid recipe__banner-text">
              <div className="row">
                <div className="col text-center">
                  <h2 className="mb-0 mb-4">{title}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="recipe__info">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-4 recipe__info-listing pt-5">
                  <div className="pt-5 px-2">
                    <div className="mb-4">
                      <h4 className="text-uppercase mb-0">{global.ingredients}</h4>
                      <DangerouslySetInnerHtml html={nl2br(ingredients)} element="div" />
                    </div>
                    <div className="mb-4">
                      <h4 className="text-uppercase mb-0">{global.difficulty}</h4>
                      <DangerouslySetInnerHtml html={nl2br(difficulty)} element="div" />
                    </div>
                    <div className="mb-4">
                      <h4 className="text-uppercase mb-0">{global.time}</h4>
                      <DangerouslySetInnerHtml html={nl2br(time)} element="div" />
                    </div>
                    <div className="mb-4">
                      <h4 className="text-uppercase mb-0">{global.portions}</h4>
                      <DangerouslySetInnerHtml html={nl2br(portions)} element="div" />
                    </div>
                    <div className="mb-4">
                      <h4 className="text-uppercase mb-0">{global.nutritional}</h4>
                      <DangerouslySetInnerHtml html={nl2br(nutritional)} element="div" />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8 recipe__info-steps pt-4 pt-md-5">
                  <div className="pt-0 pt-md-5 px-2">
                    <h4 className="text-uppercase pb-2">{global.steps}</h4>
                    <ul className="list-unstyled">
                      {steps.map(({ value: step }, index) => (
                        <li className="d-flex align-items-center py-1 px-4" key={step}>
                          <strong>{index + 1}</strong>
                          <span>{step}</span>
                        </li>
                      ))}
                    </ul>
                    <div className="recipe__interlink">
                      <DangerouslySetInnerHtml html={nl2br(interlink)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block d-md-none recipe__info-icon">
              <Image imageData={global.recipeIcon} alt={global.recipeIcon.altText} />
            </div>
          </div>
        </div>
      </TagEventTracker>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    recipe(url: { eq: $url }) {
      interlink
      urls {
        lang
        href
      }
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      title
      imageDesktop {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      imageMobile {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
      ingredients
      difficulty
      time
      portions
      nutritional
      steps {
        value
      }
    }
    global {
      ingredients
      difficulty
      time
      portions
      nutritional
      steps
      recipeIcon {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 100)
          }
        }
      }
    }
  }
`;

export default Recipe;
